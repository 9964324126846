import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap"
import Select from 'react-select';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities'
import { Settimane } from '../helper/settimaneTurniLavoro';

function FiltriPerLavoratore(props) {
  const [lavoratore, setLavoratore] = useState(null)
  const [settimana, setSettimana] = useState(null)
  const [settimanaCorrente, setSettimanaCorrente] = useState(null)

  const handleSettimana = (data) => {
    const settimanaAttuale = Settimane.find(s => s.value === data.value);
    setSettimanaCorrente(settimanaAttuale);
    setSettimana(settimanaAttuale);
  };

  useEffect(() => {
    const setMessageFromApi = (data) => {
      props.setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
    }
    console.log("props.settimana", props.settimana)
    if(props.settimana) {
      setSettimanaCorrente(props.settimana);
      setSettimana(props.settimana);
    } else {
      getFromApi(buildUrl('settimanaCorrenteTurniLavoro'), handleSettimana, setMessageFromApi);
    }
  }, [props.setMessage]);

  const filtroChanged = (newLavoratore, newSettimana) => {
    if (newLavoratore && newSettimana) {
      props.filtroChanged(newLavoratore, newSettimana.value);
    }
  }

  const lavoratoreChanged = (newLavoratore) => {
    setLavoratore(newLavoratore);
    setSettimana(settimana);
    filtroChanged(newLavoratore, settimana);
  }

  const settimanaChanged = (newSettimana) => {
    setSettimana(newSettimana);
    filtroChanged(lavoratore, newSettimana);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value === settimanaCorrente.value ? '#e6f2ff' : provided.backgroundColor,
      fontWeight: state.data.value === settimanaCorrente.value ? 'bold' : 'normal',
      color: state.data.value === settimanaCorrente.value ? '#000000' : provided.color
    }),
  };

  return (
    <Row className='filtri'>
      <Col xl={3} sm={4} xs={12} id="lavoratorefiltro">
        <p className="inEvidenza">lavoratore</p>
        <Select
          name="lavoratore"
          instanceId="lavoratore"
          options={props.lavoratori}
          onChange={lavoratoreChanged}
          placeholder="Seleziona..."
          value={lavoratore}
        />
      </Col>
      <Col xl={6} sm={8} xs={12} id="settimanafiltro">
        <p className="inEvidenza">settimana</p>
        <Select
          id="settimanafiltroselect"
          name="settimanainizio"
          instanceId="settimana"
          options={Settimane}
          onChange={settimanaChanged}
          placeholder="Seleziona..."
          value={settimana}
          styles={customStyles}
        />
      </Col>
    </Row>
  )
}

export default FiltriPerLavoratore;